import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonIcon, IonImg, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { layersOutline } from 'ionicons/icons';
import './Page.css';

const Page: React.FC = () => {
 
  return (
    <IonPage>
      <IonHeader style={{boxShadow: 'none'}}>
        <IonToolbar color="primary" style={{boxShadow: 'none'}}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
         
        </IonToolbar>
      </IonHeader>
 
      <IonContent color="pagebg">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Dashboard</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <div className="container" style={{padding: '0', top: '240px', color: '#ffffff'}}>
          <div className="db-page-header" style={{paddingTop: "100px", paddingBottom: "60px"}}>
          <IonImg src={"./assets/images/download-manager-logo.png"} style={{width: '128px', margin: '30px auto' }} />
          <h1>Download Manager</h1>
          <div style={{textAlign: 'center', marginBottom: '20px', fontSize: '14px'}}>www.wpdownloadmanager.com</div>
          </div>
          
          <div style={{margin: '-40px 30px 30px', background: '#ffffff', padding: '10px', borderRadius: '16px'}}>

          <IonRow>
            <IonCol size="6">
            <IonCard color="dbcard" style={{margin: '0'}}>
            <IonCardHeader>     
              <IonCardTitle>164</IonCardTitle>
              <IonCardSubtitle>Packages</IonCardSubtitle>
            </IonCardHeader> 
          </IonCard>
            </IonCol> 
            <IonCol size="6">
            <IonCard color="dbcard" style={{margin: '0'}}>
            <IonCardHeader>              
              <IonCardTitle>$8,323,466.00</IonCardTitle>
              <IonCardSubtitle>Sales</IonCardSubtitle>
            </IonCardHeader> 
          </IonCard>
              </IonCol> 
          </IonRow>

          <IonRow>
            
            <IonCol size="12">
            <IonCard color="dbcard" style={{margin: '0'}}>
            <IonCardHeader>              
              <IonCardTitle style={{fontSize: '20px'}}>523,323,466</IonCardTitle>
              <IonCardSubtitle>Downloads</IonCardSubtitle>
            </IonCardHeader> 
          </IonCard>
              </IonCol> 
          </IonRow>

          <IonRow>
            <IonCol size="6">
            <IonCard color="dbcard" style={{margin: '0'}}>
            <IonCardHeader>     
              <IonCardTitle>45,436</IonCardTitle>
              <IonCardSubtitle>Users</IonCardSubtitle>
            </IonCardHeader> 
          </IonCard>
            </IonCol> 
            <IonCol size="6">
            <IonCard color="dbcard" style={{margin: '0'}}>
            <IonCardHeader>              
              <IonCardTitle>4,535</IonCardTitle>
              <IonCardSubtitle>Customers</IonCardSubtitle>
            </IonCardHeader> 
          </IonCard>
              </IonCol> 
          </IonRow>

          </div>
      
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
