import { IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonHeader, IonIcon, IonImg, IonItem, IonLabel, IonList, IonMenuButton, IonPage, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { layersOutline } from 'ionicons/icons';
import './Page.css';

const Page: React.FC = () => {
 
  return (
    <IonPage>
      <IonHeader style={{boxShadow: 'none'}}>
        <IonToolbar color="primary" style={{boxShadow: 'none'}}>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
        
        </IonToolbar>
      </IonHeader>
 
      <IonContent color="pagebg">
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle>Packages</IonTitle>            
          </IonToolbar>
        </IonHeader>
        <div className="container" style={{padding: '0', top: '170px', color: '#ffffff'}}>
          <div className="db-page-header" style={{paddingTop: "40px", paddingBottom: "60px"}}>        
          <h1>Packages</h1>
          <div style={{textAlign: 'center', marginBottom: '20px', fontSize: '14px'}}>All packages</div>
          </div>
          
          <div style={{margin: '-40px 30px 30px', background: '#ffffff', padding: '10px', borderRadius: '16px'}}>

          <IonList>
      <IonItem>
        <IonLabel>Pokémon Yellow</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Mega Man X</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>The Legend of Zelda</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Pac-Man</IonLabel>
      </IonItem>
      <IonItem>
        <IonLabel>Super Mario World</IonLabel>
      </IonItem>
    </IonList>

          </div>
      
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Page;
